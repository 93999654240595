<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to="/home">
            <i class="iconfont icon-fanhui"></i>
          </router-link>
        </li>
        <li>
          <span v-if="locale === 'en'">Why us</span>
          <span v-if="locale === 'pb'">Por que nós</span>
        </li>
      </ul>
    </div>
    <div class="main main_page bg-fff">
      <div class="about_one">
        <div class="one">
          <div class="title">
            <!-- <StaticImage url="about/about_01.png" /> -->
            <span v-if="locale === 'en'">Why us</span>
            <span v-if="locale === 'pb'">Por que nós</span>
          </div>
          <div class="text" v-if="locale === 'en'">
            The industry in which our company operates belongs to fast moving
            consumer goods. With a large population and huge spending power in
            the market, Nigeria is set to show strong momentum and has a lot of
            room and prospects for growth. Our company has a long history and is
            an admirable company. Over the years, with strong technical
            strength, mature technology and perfect service system, we have
            achieved rapid development and our super shops have been widely used
            in 59 countries around the world so far. The company will give full
            play to its advantages and adhere to the tenet of "leading service,
            serving the market, treating people with integrity and pursuing
            perfection" and the corporate philosophy of "projects are people".
            We will continue to innovate in technology, equipment, service and
            management to develop more products that meet the needs of future
            development and promote the development of our personnel. At the
            same time, we will also rely on our technical and financial strength
            to actively carry out "production, learning and research"
            cooperative research projects with our counterparts at home and
            abroad, as well as with universities and research institutes. Super
            Market Limited Liability Company
          </div>
          <div class="text" v-if="locale === 'pb'">
            <p>
              • Estou honrado com sua participação, estamos principalmente para
              aumentar a visibilidade dos negócios. Abaixo explico por que você
              nos escolheu.
            </p>
            <br />
            <p>
              • Somos um aplicativo desenvolvido em conjunto com o Mercado livre
              para melhorar o ranking de vendas dos lojistas, com o objetivo de
              melhorar o ranking de vendas dos produtos dos lojistas do Mercado
              livre!
            </p>
            <br />
            <p>
              • Em agosto de 2007, o Mercado livre foi listado na Nasdaq. O
              shopping online Mercado Livre inicia seu serviço. O Mercado livre
              é a maior plataforma C2C doméstica no Brasil para aumentar
              amplamente o reconhecimento de sua marca enquanto acelera a
              atividade de M&A. Este é um passo importante para novos negócios e
              serviços que acabarão por formar o ecossistema de negócios do
              Mercado Livre.
            </p>
            <br />
            <p>
              •O número total de usuários cadastrados certificados do mercado
              livre atingiu 50,2 milhões, e o número de visitas ao Mercado livre
              está entre os 50 maiores do mundo. Em 2009, o volume total de
              vendas do Mercado livre foi de 29,5 milhões. trimestre de 2009,
              foi concluída através de Mercado Pago. 700.000 pagamentos foram
              feitos, e os pagamentos totais para o ano de 2009 chegou a US $
              382,6 milhões. O valor total é de US$ 2,75 bilhões.No terceiro
              trimestre de 2010, os novos produtos chegaram a 10,4 milhões, uma
              taxa de crescimento de 30,1%.
            </p>
            <br />
            <p>
              •Nossa empresa tem uma longa história. Ao longo dos anos, com
              tecnologia madura e serviços perfeitos, a empresa se desenvolveu
              rapidamente. Atualmente, nosso negócio envolve um total de 13
              países e regiões (Brasil, Argentina, Chile, Colômbia, Costa Rica,
              Equador, México, Panamá, Peru, Dominica, Paraguai, Venezuela e
              Portugal) Devido à grande quantidade de informações de recarga,
              por favor, verifique o número da conta desta plataforma
              cuidadosamente antes de recarregar. A plataforma altera as contas
              bancárias de tempos em tempos. Em caso de dúvidas, clique na
              plataforma para consulta online de atendimento ao cliente.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useI18n } from "@/lang";
import { sysDataDictionaryWbObj } from "@/hooks/sysDataDictionaryWbObj";
export default {
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
      sysDataDictionaryWbObj,
    };
  },
};
</script>
<style>
/* Current page style */
</style>
